<template>
  <div class="products category-leaflet">
    <Breadcrumb :items="breadcrumbs" />
    <category-block
      :target="category"
      position="position1"
      class="category-block"
    />
    <CategoryTitle :category="category" :key="category.categoryId" />

    <v-container>
      <div class="slider sticky-slider">
        <swiper :options="swiperOption" ref="swiperRef">
          <swiper-slide
            v-for="subCategory in category.children"
            :key="subCategory.categoryId"
          >
            <v-card
              outlined
              height="72px"
              width="170px"
              :to="{
                name: 'Category',
                params: {
                  pathMatch: category.slug
                },
                query: { categoryId: subCategory.categoryId }
              }"
              class="category-card d-flex flex-row align-top justify-left"
            >
              <v-img
                contain
                :src="
                  $ebsn.meta(
                    subCategory,
                    'category_info.ICON',
                    $ebsn.meta(subCategory, 'category_info.THUMB')
                  )
                "
                :alt="subCategory.name"
                height="44"
                class="center-img mt-3 ml-1"
              ></v-img>
              <v-card-text> {{ subCategory.name }} </v-card-text>
            </v-card>
          </swiper-slide>
        </swiper>
        <swiper-paginator
          :showBullets="true"
          paginationClass="category-list"
          :length="category.children.length"
        />
        <v-divider />
      </div>
      <div v-if="!categoryId">
        <h3>{{ $t("categoryLeaflet.allProducts") }}</h3>
        <ProductListGrid
          class="mt-5"
          :hidePromoFilter="true"
          :parentCategoryId="category.categoryId"
          :infiniteScroll="false"
          hideFilterString="Categorie,Main Term"
          @productsCount="updateCount"
          :pageSize="$ebsn.meta(category, 'template_model.PAGE_SIZE')"
          :virtualScrollViewport="$vuetify.breakpoint.xsOnly"
          :virtualPageSize="$vuetify.breakpoint.xsOnly ? 4 : 1"
        />
      </div>
      <div v-else>
        <h3>
          <v-btn @click="categoryId = null" icon elevation="0">
            <v-icon>$chevronLeft</v-icon>
          </v-btn>
          {{ selectedCategory.name }}
        </h3>
        <ProductListGrid
          :parentCategoryId="category.categoryId"
          :categoryId="categoryId"
          :key="categoryId"
          :infiniteScroll="false"
          :hidePromoFilter="true"
          hideFilterString="Categorie,Main Term"
          @productsCount="updateCount"
          :pageSize="$ebsn.meta(category, 'template_model.PAGE_SIZE')"
          :virtualScrollViewport="$vuetify.breakpoint.xsOnly"
          :virtualPageSize="$vuetify.breakpoint.xsOnly ? 4 : 1"
        />
      </div>
    </v-container>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.category-leaflet {
  .v-card__subtitle,
  .v-card__text {
    font-size: 1rem;
    font-weight: bold;
    padding: 8px;
  }
  .router-link-exact-active {
    background: var(--v-grey-lighten1);
    border-color: var(--v-primary-base);
  }
  // @media #{map-get($display-breakpoints, 'lg-and-up')} {
  //   .sticky-slider {
  //     position: sticky;
  //     padding-top: 10px;
  //     top: 149px;
  //     z-index: 7;
  //     background-color: white;
  //   }
  // }
}
</style>
<script>
// @ is an alias to /src
import toNumber from "lodash/toNumber";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import categoryMixins from "~/mixins/category";
import SwiperPaginator from "../SwiperPaginator.vue";

export default {
  name: "CategoryLeaflet",
  mixins: [categoryMixins],
  data() {
    return {
      categoryId: null,
      count: null,
      swiperOption: {
        slidesPerGroup: 1,
        slidesPerView: "auto",
        spaceBetween: 10,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: "#pagination-category-list",
          clickable: true
        },
        navigation: {
          prevEl: "#prev-category-list",
          nextEl: "#next-category-list"
        }
      }
    };
  },
  components: {
    CategoryTitle,
    ProductListGrid,
    Breadcrumb,
    SwiperPaginator
    // ,
    // CategoryBlock
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Home",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    selectedCategory() {
      if (this.categoryId) {
        return this.category.children.find(
          item => item.categoryId == this.categoryId
        );
      } else {
        return null;
      }
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  created() {
    this.categoryId = toNumber(this.$route.query.categoryId);
  },
  watch: {
    async "$route.query.categoryId"() {
      this.resetFilters();
      this.categoryId = this.$route.query.categoryId;
    }
  }
};
</script>
